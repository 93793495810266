var initPhotoSwipeFromDOM = function(gallerySelector) {

  // parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)
  var parseThumbnailElements = function(el) {
    var thumbElements = $(el).find('figure'),
      numNodes,
      items = [],
      figureEl,
      linkEl,
      size,
      item;
    // console.log('parseThumbnailElements', el, thumbElements);
    thumbElements = thumbElements.filter(function( index ) {
      if ($(this).parents('.slick-slide').length > 0) {
        return !($(this).parents('.slick-slide').hasClass('slick-cloned'));
      } else {
        return true;
      }
    });
    numNodes = thumbElements.length
    for (var i = 0; i < numNodes; i++) {

      figureEl = thumbElements[i]; // <figure> element

      // include only element nodes
      if (figureEl.nodeType !== 1) {
        continue;
      }

      linkEl = figureEl.children[0]; // <a> element

      size = linkEl.getAttribute('data-size').split('x');

      // create slide object
      item = {
        src: linkEl.getAttribute('href'),
        w: parseInt(size[0], 10),
        h: parseInt(size[1], 10)
      };



      if (figureEl.children.length > 1) {
        // <figcaption> content
        item.title = figureEl.children[1].innerHTML;
      }

      if (linkEl.children.length > 0) {
        // <img> thumbnail element, retrieving thumbnail url
        item.msrc = linkEl.children[0].getAttribute('src');
      }

      item.el = figureEl; // save link to element for getThumbBoundsFn
      items.push(item);
    }

    return items;
  };

  // find nearest parent element
  var closest = function closest(el, fn) {
    return el && (fn(el) ? el : closest(el.parentNode, fn));
  };

  // triggers when user clicks on thumbnail
  var onThumbnailsClick = function(e) {
    e = e || window.event;
    e.preventDefault ? e.preventDefault() : e.returnValue = false;

    var eTarget = e.target || e.srcElement;

    // find root element of slide
    var clickedListItem = closest(eTarget, function(el) {
      return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
    });

    if (!clickedListItem) {
      return;
    }

    // find index of clicked item by looping through all child nodes
    // alternatively, you may define index via data- attribute
    var clickedGallery = jQuery(clickedListItem).parents(gallerySelector)[0],
      childNodes = jQuery(clickedGallery).find('figure'),
      numChildNodes,
      nodeIndex = 0,
      index;
      // console.log(clickedGallery, childNodes);
    childNodes = childNodes.filter(function( index ) {
      if ($(this).parents('.slick-slide').length > 0) {
        return !($(this).parents('.slick-slide').hasClass('slick-cloned'));
      } else {
        return true;
      }
    });
    numChildNodes = childNodes.length;

    for (var i = 0; i < numChildNodes; i++) {
      if (childNodes[i].nodeType !== 1) {
        continue;
      }
      if (childNodes[i] === clickedListItem) {
        index = nodeIndex;
        break;
      }
      if ($(clickedListItem).parents('.slick-cloned').length > 0) {
        // console.log($(childNodes[i]).data('key'), $(clickedListItem).data('key'));
        if ($(childNodes[i]).data('key') == $(clickedListItem).data('key')) {
          index = nodeIndex;
          break;
        }
      }
      nodeIndex++;
    }



    if (index >= 0) {
      // open PhotoSwipe if valid index found
      openPhotoSwipe(index, clickedGallery);
    }
    return false;
  };

  // parse picture index and gallery index from URL (#&pid=1&gid=2)
  var photoswipeParseHash = function() {
    var hash = window.location.hash.substring(1),
      params = {};

    if (hash.length < 5) {
      return params;
    }

    var vars = hash.split('&');
    for (var i = 0; i < vars.length; i++) {
      if (!vars[i]) {
        continue;
      }
      var pair = vars[i].split('=');
      if (pair.length < 2) {
        continue;
      }
      params[pair[0]] = pair[1];
    }

    if (params.gid) {
      params.gid = parseInt(params.gid, 10);
    }

    return params;
  };

  var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
    var pswpElement = document.querySelectorAll('.pswp')[0],
      gallery,
      options,
      items;

    items = parseThumbnailElements(galleryElement);

    // define options (if needed)
    options = {

      // define gallery index (for URL)
      shareEl: false,
      galleryUID: galleryElement.getAttribute('data-pswp-uid'),

      getThumbBoundsFn: function(index) {
        // See Options -> getThumbBoundsFn section of documentation for more info
        var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
          pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
          rect = thumbnail.getBoundingClientRect();

        return {
          x: rect.left,
          y: rect.top + pageYScroll,
          w: rect.width
        };
      }

    };

    // PhotoSwipe opened from URL
    if (fromURL) {
      if (options.galleryPIDs) {
        // parse real index when custom PIDs are used
        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
        for (var j = 0; j < items.length; j++) {
          if (items[j].pid == index) {
            options.index = j;
            break;
          }
        }
      } else {
        // in URL indexes start from 1
        options.index = parseInt(index, 10) - 1;
      }
    } else {
      options.index = parseInt(index, 10);
    }

    // exit if index not found
    if (isNaN(options.index)) {
      return;
    }

    if (disableAnimation) {
      options.showAnimationDuration = 0;
    }
    // console.log(items, $(pswpElement).next('.pswp__thumbs').length > 0);
    if ($(pswpElement).next('.pswp__thumbs').length > 0) {
      var $thumbsHolder = $(pswpElement).next('.pswp__thumbs');
      var thumbsHTML = '<ul class="list">';
      $.each(items, function(key, item) {
        thumbsHTML += '<li'+(key==0?' class="active"':'')+'>';
        thumbsHTML += '<div class="img" style="background-image: url(\''+item.msrc+'\');"></div>';
        thumbsHTML += '</li>';
      })
      thumbsHTML += '</ul>';
      $thumbsHolder.html(thumbsHTML).show();
      $('.pswp__button--thumbs').show();
    }
    // Pass data to PhotoSwipe and initialize it
    gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
    function galleryUpdatePosition(gallery) {
      gallery.applyZoomPan(gallery.getZoomLevel(), ($(gallery.container).width()-gallery.currItem.w*gallery.getZoomLevel())/2 , ($(gallery.container).height()-gallery.currItem.h*gallery.getZoomLevel())/2);
      gallery.updateSize(true);
    }
    if ($(pswpElement).next('.pswp__thumbs').length > 0) {
      $('body').addClass('pswp__thumbs--open');
      galleryUpdatePosition(gallery)
      $(document).on('click', '.pswp__thumbs .img', function(event) {
        gallery.goTo($(".pswp__thumbs li").index($(this).parent()));
      }).on('click', '.pswp__button--thumbs', function(event) {
        $('body').toggleClass('pswp__thumbs--open');
        galleryUpdatePosition(gallery)
        return false
      });
      gallery.listen('close', function () {
        $('body').removeClass('pswp__thumbs--open');
      });
      gallery.listen('imageLoadComplete beforeChange afterChange', function () {
        galleryUpdatePosition(gallery);
      });
      gallery.listen('afterChange', function() {
        var ind = gallery.getCurrentIndex();
        $('.pswp__thumbs li').eq(ind).addClass('active');
        $('.pswp__thumbs li').eq(ind).siblings().removeClass('active');
      });
    }
  };

  // loop through all gallery elements and bind events
  var galleryElements = document.querySelectorAll(gallerySelector);

  for (var i = 0, l = galleryElements.length; i < l; i++) {
    galleryElements[i].setAttribute('data-pswp-uid', i + 1);
    galleryElements[i].onclick = onThumbnailsClick;
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  var hashData = photoswipeParseHash();
  if (hashData.pid && hashData.gid) {
    openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
  }
};
